import React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/Seo"
import HomeHero from '../components/HomeHero'
import HomeAbout from '../components/HomeAbout'
import HomeServices from '../components/HomeServices'
import ContactCTA from '../components/ContactCTA'
import HomeBlogList from '../components/HomeBlogList'
import HomeBios from '../components/HomeBios'
import NewsletterCTA from '../components/NewsletterCTA'

const IndexPage = () => {
  return (
    <main>
      <HomeHero />
      <HomeAbout />
      <HomeServices />
      <ContactCTA />
      <HomeBlogList />
      <HomeBios />
      <NewsletterCTA />
    </main>
  )
}

export default IndexPage

export const Head = (seo) => {
  const seoData = seo.data.SeoMeta
  return (
    <>
      {seoData.edges.map(({ node }) => (
        <Seo key={node.id} title={node.seoTitle} description={node.seoDescription.seoDescription} image={node.seoImage.url} />
      ))}
    </>
  )
}

export const SeoContent = graphql`
  {
    SeoMeta: allContentfulHomePage {
      edges {
        node {
          id
          seoTitle
          seoDescription {
            seoDescription
          }
          seoImage {
            url
          }
        }
      }
    }
  }
`;
