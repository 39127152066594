import React from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

const FeaturedBioSection = styled.section`
  margin-bottom: 120px;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    column-gap: 90px;
    row-gap: 50px;
    margin-bottom: 50px;

    &:last-child .bio-content {
      order: 1;
    }

    @media(min-width: 992px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:last-child .bio-content {
        order: -1;
      }
    }
  }

  .bio-photo-container {
    position: relative;
    max-width: 260px;
    min-width: 260px;
    width: 100%;

    @media(min-width: 992px) {
      max-width: 420px;
      min-width: 420px;
    }

    .bio-photo,
    .bio-frame-back,
    .bio-frame-front {
      width: 100%;
      max-width: 400px;
      height: auto;
    }

    .bio-photo,
    .bio-frame-front {
      position: absolute;
      top: 20px;
      left: 20px;
    }
  }

  .bio-content {
    text-align: center;

    @media(min-width: 992px) {
      text-align: left;
    }

    h2 {
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 0;
      font-size: 20px;
      color: var(--gray);
      line-height: 1.3;
      margin-bottom: 0;
    }
  }
`

const HomeBios = () => {

  const data = useStaticQuery(graphql`
    {
      bios: allContentfulHomePage {
        edges {
          node {
            id
            bio1Photo {
              gatsbyImageData(width: 800, placeholder: BLURRED)
              title
            }
            bio1Title
            bio1Paragraph {
              bio1Paragraph
            }
            bio2Photo {
              gatsbyImageData(width: 800, placeholder: BLURRED)
              title
            }
            bio2Title
            bio2Paragraph {
              bio2Paragraph
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {data.bios.edges.map(({ node }) => (
        <FeaturedBioSection key={node.id}>
          <div className="container">
            <div className="bio-photo-container">
              <StaticImage
                src="../images/home-photo-frame-back.png"
                width={400}
                height={400}
                alt=""
                className="bio-frame-back"
              />
              <GatsbyImage image={node.bio1Photo.gatsbyImageData} alt={node.bio1Photo.title} className="bio-photo" />
              <StaticImage
                src="../images/home-photo-frame-front.png"
                width={400}
                height={400}
                alt=""
                className="bio-frame-front"
              />
            </div>
            <div className="bio-content">
              <h2>{node.bio1Title}</h2>
              <p>{node.bio1Paragraph.bio1Paragraph}</p>
            </div>
          </div>
          <div className="container">
            <div className="bio-content">
              <h2>{node.bio2Title}</h2>
              <p>{node.bio2Paragraph.bio2Paragraph}</p>
            </div>
            <div className="bio-photo-container">
              <StaticImage
                src="../images/home-photo-frame-back.png"
                width={400}
                height={400}
                alt=""
                className="bio-frame-back"
              />
              <GatsbyImage image={node.bio2Photo.gatsbyImageData} alt={node.bio2Photo.title} className="bio-photo" />
              <StaticImage
                src="../images/home-photo-frame-front.png"
                width={400}
                height={400}
                alt=""
                className="bio-frame-front"
              />
            </div>
          </div>
        </FeaturedBioSection>
      ))}
    </>
  )
}

export default HomeBios
