import React from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

const BlogListSection = styled.section`
  margin-bottom: 120px;

  .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    column-gap: 0;
    row-gap: 50px;

    @media(min-width: 1200px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    .blog-section-intro {
      max-width: 430px;
      text-align: center;

      @media(min-width: 1200px) {
        max-width: 260px;
        text-align: left;
      }

      p {
        margin-bottom: 30px;
        color: var(--gray);
      }

      a {
        color: var(--pink);
      }
    }

    .blog-list-container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 30px;

      @media(min-width: 1200px) {
        justify-content: flex-start;
      }

      img {
        width: 280px;
        height: 158px;
        object-fit: cover;
        object-position: center;
        border: 1px solid var(--pink);
        margin-bottom: 15px;
      }

      a {
        text-decoration: none;
        color: var(--white);

        &:hover {
          text-decoration: underline;
          text-decoration-color: var(--pink);
          color: var(--white);
        }

        h3 {
          font-size: 26px;
          max-width: 280px;
          width: 100%;
          color: var(--white);
          line-height: 1.15;
          margin-bottom: 0;
        }
      }
    }
  }
`

const HomeBlogList = () => {

  const data = useStaticQuery(graphql`
    {
      blogText: allContentfulHomePage {
        edges {
          node {
            id
            blogSectionH2
            blogSectionDescription
            blogSectionLinkText
            blogSectionLinkUrl
          }
        }
      }
      blogList: allContentfulBlogPost(
        limit: 3
        sort: {fields: publishDate, order: DESC}
      ) {
        edges {
          node {
            id
            title
            slug
            featuredImage {
              gatsbyImageData(width: 560, placeholder: BLURRED)
              title
            }
            publishDate
          }
        }
      }
    }
  `)

  return (
    <BlogListSection>
      <div className="container">
        <div className="blog-section-intro">
          {data.blogText.edges.map(({ node }) => (
            <div key={node.id}>
              <h2>{node.blogSectionH2}</h2>
              <p>{node.blogSectionDescription}</p>
              <a href={node.blogSectionLinkUrl}>{node.blogSectionLinkText}</a>
            </div>
          ))}
        </div>
        <div className="blog-list-container">
          {data.blogList.edges.map(({ node }) => (
            <div key={node.id}>
              <a href={node.slug}>
                <GatsbyImage image={node.featuredImage.gatsbyImageData} alt={node.featuredImage.title} />
              </a>
              <a href={node.slug}>
                <h3>{node.title}</h3>
              </a>
            </div>
          ))}
        </div>
      </div>
    </BlogListSection>
  )
}

export default HomeBlogList
