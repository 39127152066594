import React from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const ContactCTAContent = styled.section`
  position: relative;
  margin-bottom: 120px;

  .contact-cta-bg {
    position: relative;
    min-height: 230px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &:before {
      background: linear-gradient(to bottom, var(--black), rgba(0 0 0 / 0%) 50%, rgba(0 0 0 / 0%));
    }

    &::after {
      background: linear-gradient(to top, var(--black), rgba(0 0 0 / 0%) 50%, rgba(0 0 0 / 0%));
    }
  }

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    z-index: 2;

    @media(min-width: 1200px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .cta-grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 80px;
    row-gap: 30px;
    z-index: 1;
    position: relative;

    h2 {
      font-size: 24px;
      line-height: 1.1;
      font-family: var(--body-font);
      text-shadow: 0 1px 6px rgba(0 0 0 / 50%);
      max-width: 660px;
      width: 100%;
      margin-bottom: 0;
      text-align: center;

      @media(min-width: 992px) {
        text-align: left;
      }
    }
  }
`

export function ContactCTA() {

  const data = useStaticQuery(graphql`
    {
      ContactCTA: allContentfulContactCta {
        edges {
          node {
            contentful_id
            contactCtaText
            contactCtaButtonText
            contactCtaButtonUrl
          }
        }
      }
    }
  `)

  return (
    <ContactCTAContent>
      <StaticImage
        src="../images/contact-cta-bg.jpg"
        layout="fullWidth"
        placeholder="blurred"
        objectFit="cover"
        alt=""
        className="contact-cta-bg"
      />
      {data.ContactCTA.edges.map(({ node }) => (
        <div className="container" key={node.contentful_id}>
          <div className="cta-grid">
            <h2>{node.contactCtaText}</h2>
            {/* <Link to={node.contactCtaButtonUrl} className="btn">{node.contactCtaButtonText}</Link> */}
            <a href={node.contactCtaButtonUrl} className="btn">{node.contactCtaButtonText}</a>
          </div>
        </div>
      ))}
    </ContactCTAContent>
  );
}

export default ContactCTA