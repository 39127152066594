import React from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql } from 'gatsby'

import HeroBgImage from '../images/home-hero-bg.jpg'

const HeroSection = styled.section`
  position: relative;
  display: flex;
  min-height: 750px;
  margin-top: -100px;
  margin-bottom: 65px;
  background-image: url(${HeroBgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media(min-width: 576px) {
    min-height: 575px;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, var(--black), rgba(0 0 0 / 0%));
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, var(--black), rgba(0 0 0 / 0%));
  }

  .container {
    align-self: center;
    text-align: center;
  }

  h1 {
    max-width: 950px;
    margin: 0 auto 35px;
    text-shadow: 0 1px 6px rgba(0 0 0 / 50%);
  }

  p {
    max-width: 690px;
    margin: 0 auto 35px;
    color: var(--gray);
    font-size: 21px;
  }
`

const HomeHero = () => {

  const data = useStaticQuery(graphql`
    {
      hero: allContentfulHomePage {
        edges {
          node {
            id
            heroH1
            heroParagraph {
              heroParagraph
            }
            heroButtonText
            heroButtonUrl
          }
        }
      }
    }
  `)

  return (
    <>
    {data.hero.edges.map(({ node }) => (
      <HeroSection key={node.id}>
        <div className="container">
          <h1>{node.heroH1}</h1>
          <p>{node.heroParagraph.heroParagraph}</p>
          {/* <Link to={node.heroButtonUrl} className="btn">{node.heroButtonText}</Link> */}
          <a href={node.heroButtonUrl} className="btn">{node.heroButtonText}</a>
        </div>
      </HeroSection>
    ))}
    </>
  )
}

export default HomeHero
