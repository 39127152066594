import React from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const ServicesSection = styled.section`
  text-align: center;
  margin-bottom: 120px;

  p {
    max-width: 700px;
    margin: 0 auto 50px;
    color: var(--gray);
  }
`

const ServiceCardGrid = styled.div`
  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    column-gap: 35px;
    row-gap: 60px;
  }

  .service-flip-card {
    background-color: var(--black);
    width: 270px;
    height: 270px;
    perspective: 1000px;
  }

  .service-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .service-flip-card:hover .service-flip-card-inner {
    transform: rotateY(180deg);
  }

  .service-flip-card-front,
  .service-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  .service-flip-card-front {
    opacity: 0.99;
    backface-visibility: hidden;

    .card-front-content {
      background-color: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);

      p {
        color: var(--white);
        font-size: 22px;
        line-height: 1.1;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }
  }

  .service-flip-card-back {
    opacity: 0.99;
    transform: rotateY(180deg);
    top: 0;

    .card-back-content {
      background-color: var(--gray);
      position: absolute;
      top: 14px;
      left: 13px;
      width: 242px;
      height: 243px;
      padding: 20px 10px;
      box-sizing: border-box;
      overflow-y: auto;
      z-index: 6;

      h4 {
        font-size: 21px;
        line-height: 1.1;
        margin-bottom: 10px;
        color: var(--black);
        font-family: var(--body-font);
        font-weight: var(--font-bold);
        text-transform: uppercase;
      }

      p {
        color: var(--black);
        font-size: 17px;
        line-height: 1.3;
        margin-bottom: 7px;
      }

      a {
        font-size: 17px;
        color: var(--pink);
      }
    }
  }
`

const HomeServices = () => {

  const data = useStaticQuery(graphql`
    {
      services: allContentfulHomePage {
        edges {
          node {
            id
            servicesH2
            servicesParagraph {
              servicesParagraph
            }
          }
        }
      }
      serviceCards: allContentfulServiceOrder {
        edges {
          node {
            contentful_id
            serviceOrder {
              contentful_id
              title
              slug
              shortDescription {
                shortDescription
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ServicesSection>
      {data.services.edges.map(({ node }) => (
        <div key={node.id} className="container">
          <h2>{node.servicesH2}</h2>
          <p>{node.servicesParagraph.servicesParagraph}</p>
        </div>
      ))}

      <ServiceCardGrid>
        {data.serviceCards.edges.map(({ node }) => (
          <div key={node.contentful_id} className="container">
          {node.serviceOrder && node.serviceOrder.map((item) => (
            <div key={item.contentful_id} className="service-flip-card">
              <div className="service-flip-card-inner">
                <div className="service-flip-card-front">
                  <StaticImage
                    src="../images/home-services-frame-graphic.png"
                    width={270}
                    height={270}
                    alt=""
                  />
                  <div className="card-front-content">
                    <p>{item.title}</p>
                  </div>
                </div>
                <div className="service-flip-card-back">
                  <StaticImage
                    src="../images/home-services-frame-graphic.png"
                    width={270}
                    height={270}
                    alt=""
                  />
                  <div className="card-back-content">
                    <h4>{item.title}</h4>
                    <p>{item.shortDescription.shortDescription}</p>
                    <a href={`/services/${item.slug}`}>Service Details</a>
                  </div>
                </div>
              </div>
            </div>
          ))}
          </div>
        ))}
      </ServiceCardGrid>
    </ServicesSection>
  )
}

export default HomeServices
