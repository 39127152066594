import React from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

const AboutSection = styled.section`
  margin-bottom: 110px;

  .row-two-col {
    align-items: center;
  }

  p {
    color: var(--gray);
    margin-bottom: 25px;
  }

  .gatsby-image-wrapper {
    max-width: 650px;
    width: 100%;
    height: auto;
  }
`

const HomeAbout = () => {

  const data = useStaticQuery(graphql`
    {
      about: allContentfulHomePage {
        edges {
          node {
            id
            aboutH2
            aboutParagraph {
              aboutParagraph
            }
            aboutButtonText
            aboutButtonUrl
            aboutImage {
              gatsbyImageData(width: 1100, placeholder: BLURRED)
              title
            }
          }
        }
      }
    }
  `)

  return (
    <>
    {data.about.edges.map(({ node }) => (
      <AboutSection key={node.id}>
        <div className="container">
          <div className="row-two-col">
            <div className="col-1">
              <h2>{node.aboutH2}</h2>
              <p>{node.aboutParagraph.aboutParagraph}</p>
              <a href={node.aboutButtonUrl} className="btn">{node.aboutButtonText}</a>
            </div>
            <div className="col-2">
              <GatsbyImage image={node.aboutImage.gatsbyImageData} alt={node.aboutImage.title} />
            </div>
          </div>
        </div>
      </AboutSection>
    ))}
    </>
  )
}

export default HomeAbout
